import styled from "styled-components";
import { applyStyleModifiers } from "styled-components-modifiers";
import ActiveLink from "@components/common/links/ActiveLink";

const NAVIGATION_LINK_MODIFER_CONFIG = {
  active: ({ theme }) => `
        color: ${theme.colors.primary};
    `,
};

const LinkStyle = styled.a`
  color: ${(props) => props.theme.colors.darkPrimary};
  font-family: ${(props) => props.theme.fonts.paragraph};
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 29px;
  transition: ${(props) => props.theme.transitions.standard};
  letter-spacing: 0.008em;
  font-size: 1.65rem;
  text-decoration: none;
  ${applyStyleModifiers(NAVIGATION_LINK_MODIFER_CONFIG)};
  :hover {
    color: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    font-size: 1.6rem;
    margin-right: 18px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    font-size: 1.45rem;
    margin-right: 15px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    display: none;
  }
`;

const StoreLinkStyle = styled(LinkStyle)`
  background-color: #69d68c;
  padding: 4px 10px;
  border-radius: 100px;
  @media (max-width: 808px) {
    display: none;
  }
`;

// Strict associate is there becuase if you do a loose associate, then the [id] and [productCategories] will not trigger active
const NavLink = (props) => (
  <>
    {props.storeLink ? (
      <StoreLinkStyle
        href={props.href}
        target="_blank"
      >
        {props.children}
      </StoreLinkStyle>
    ) : (
      <>
        {props.realLink ? (
          <LinkStyle href={props.href}>{props.children}</LinkStyle>
        ) : (
          <ActiveLink
            href={props.href}
            as={props.as}
            passHref
            looseAssociation={true}
          >
            <LinkStyle>{props.children}</LinkStyle>
          </ActiveLink>
        )}
      </>
    )}
  </>
);

export default NavLink;
